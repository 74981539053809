/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TablePagination from '@mui/material/TablePagination';
import Pagination from 'material-ui-flat-pagination';

import Paper from '@mui/material/Paper';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import TablePaginationActions from './TablePaginationActions';

import UIDateFormater, {
  DateType,
} from '../../../containers/UIhelper/UIDateFormater';
import { COUPLE_SAMPLES_COUNT_FOR_PAGE } from '../../../constants/Misc';
import _ from 'lodash';

import './CoupleOutputTab.scss';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      padding: '8px 0 !important',
      borderBottom: 'unset',
      fontSize: '1.2rem',
    },
  },
  container: {
    // maxHeight: '335px',
  },
  innerContainer: {
    maxHeight: '314px',
    minHeight: '31vh',
    boxShadow: 'none',
  },
  head: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '12px',
  },
  trSelected: {
    backgroundColor: '#ffefc5 !important',
  },
  pagination: {
    fontSize: '1.2rem',
  },
});

function getAlgoStatusClassName(sample, context) {
  let classNameVal = 'algo ';
  let tooltipValue;
  switch (sample.IsLeak) {
    case 0:
      classNameVal += 'green';
      tooltipValue = context.t('no_leak');
      break;
    case 1:
    case 2:
      if (sample.IsBurst) {
        classNameVal += 'red';
        tooltipValue = context.t('suspected_fast_developing_leak');
      } else {
        classNameVal += 'orange';
        tooltipValue = context.t('suspected_leak');
      }
      // if burst - RED 'Suspected fast developing leak'
      break;
    case 3:
      classNameVal += 'blue';
      tooltipValue = context.t('consumption');
      break;
    case 4:
      classNameVal += 'blue';
      tooltipValue = context.t('consumption');
      break;
    case 7:
      classNameVal += 'yellow';
      tooltipValue = context.t('uncertain');
      break;
    default:
      classNameVal += 'grey';
      tooltipValue = context.t('undefined');
      break;
  }

  return {
    class: classNameVal,
    title: tooltipValue,
  };
}

function getDistance(value, factor) {
  return (value * factor).toFixed();
}

// const setColWidth = showOnlyRelatedProbability
//   ? { width: '10%' }
//   : { width: '8%' };

function Row(props, context) {
  const {
    isExtended,
    row,
    handleSelection,
    selectedRow,
    timeZone,
    expandedRow,
    setExpandedRow,
    distanceFactor,
    showOnlyRelatedProbability,
  } = props;
  const classes = useRowStyles();
  const isLeakData = getAlgoStatusClassName(row, context);
  const open = row.ID === expandedRow;
  const hasMoreLeaks = row.multi
    ? row.multi.some((item) => item.LeakID)
    : false;
  const iconColor = hasMoreLeaks ? 'secondary' : 'default';

  const allDistancesInline = [getDistance(row.DistanceIM, distanceFactor)];
  if (row.multi) {
    row.multi.forEach((x) => {
      allDistancesInline.push(getDistance(x.DistanceIM, distanceFactor));
    });
  }

  return (
    <React.Fragment>
      <TableRow
        hover
        className={classes.root}
        onClick={() => {
          if (selectedRow == null || row.ID != selectedRow.ID) {
            handleSelection(row);
          }
        }}
        selected={_.isEqual(row, selectedRow)}
        classes={{
          selected: classes.trSelected,
        }}
      >
        {isExtended && (
          <TableCell padding='none'>
            {row.multi && (
              <IconButton
                aria-label={context.t('expand_row')}
                size='small'
                color={iconColor}
                onClick={() => {
                  const value = row.ID === expandedRow ? null : row.ID;
                  setExpandedRow(value);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        )}

        <TableCell padding='none' component='td'>
          <div className={isLeakData.class} title={isLeakData.title} />
        </TableCell>
        <TableCell padding='none' className={classes.head}>
          {row.RM && row.RM < props.rmThreshold && (
            <img
              width='17'
              height='14'
              title={context.t('low_rm', {
                rm: row.RM ? row.RM.toFixed(1) : '-',
              })}
              src={require('../../../../images/bad-rm-icon.png')}
            />
          )}
        </TableCell>
        <TableCell padding='none' align='center'>
          {UIDateFormater(
            row.SampleDateAndTime,
            DateType.DATE_AND_TIME,
            timeZone
          )}
        </TableCell>
        <TableCell padding='none' align='center'>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const selectedAlert = {
                type: 'alert',
                id: row.LeakID,
              };
              props.navigateToFeature(row.LeakID, selectedAlert);
            }}
          >
            {row.LeakID}
          </a>
        </TableCell>
        <TableCell padding='none' align='center'>
          {row.IntensityA}
        </TableCell>
        <TableCell padding='none' align='center'>
          {row.IntensityI}
        </TableCell>
        <TableCell padding='none' align='center'>
          {/* {row.haveDistanceMatch ? <strong>{getDistance(row.DistanceAM, distanceFactor)}</strong> : getDistance(row.DistanceAM, distanceFactor)} */}
          {getDistance(row.DistanceAM, distanceFactor)}
        </TableCell>
        {/* <TableCell padding='none' align='center' style={{ display: "flex", justifyContent: "space-between" }}> */}
        <TableCell padding='none' align='center'>
          {getDistance(row.DistanceIM, distanceFactor)}
          {/* {row.distancesHTML.map((val, index, allValues) => (
            <span key={index} style={{ flex: 1, textAlign: "center", borderRight: index < allValues.length - 1 ? "1px solid #ccc" : "none" }}> 
              {val.bold ? <strong>{val.value}</strong> : val.value}
            </span>
          ))} */}
        </TableCell>
        {showOnlyRelatedProbability ? (
          <TableCell padding='none' align='center'>
            {row.Probability}
          </TableCell>
        ) : (
          [
            <TableCell key={'prob-' + row.ID} padding='none' align='center'>
              {row.Probability}
            </TableCell>,
            <TableCell
              key={'prob-irrg-' + row.ID}
              padding='none'
              align='center'
            >
              {row.probability_irrigation}
            </TableCell>,
          ]
        )}
        {/* <TableCell padding='none' align="center" title={row.RM}>{row.RM ? row.RM.toFixed() : ''}</TableCell> */}
      </TableRow>
      {isExtended && (
        <TableRow>
          <TableCell
            style={{ padding: 0 }}
            colSpan={showOnlyRelatedProbability ? 10 : 11}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box
                style={{ backgroundColor: '#e7f1ff' }}
                margin={0}
                padding={0}
                // boxShadow={3}
              >
                {/* <Typography variant="h5" gutterBottom component="div">
                  {context.t('More Samples')}
                </Typography> */}
                <Table size='small'>
                  <TableHead style={{ visibility: 'collapse' }}>
                    <TableRow>
                      <TableCell padding='none' style={{ width: '32px' }} />
                      <TableCell padding='none'>
                        <img
                          src={require('../../../../images/drop.png')}
                          height={20}
                          width={20}
                          // style={{ margin: '5px' }}
                        />
                      </TableCell>
                      <TableCell
                        padding='none'
                        className={classes.head}
                        align='center'
                        style={{ width: '20%' }}
                      >
                        {context.t('sample_time')}
                      </TableCell>
                      <TableCell
                        padding='none'
                        className={classes.head}
                        align='center'
                      >
                        {context.t('leak_id')}
                      </TableCell>
                      <TableCell
                        padding='none'
                        className={classes.head}
                        align='center'
                      >
                        {context.t('weighted_intensity')}
                      </TableCell>
                      <TableCell
                        padding='none'
                        className={classes.head}
                        align='center'
                      >
                        {context.t('sample_intensity')}
                      </TableCell>
                      <TableCell
                        padding='none'
                        className={classes.head}
                        align='center'
                      >
                        {context.t('weighted_distance')}
                      </TableCell>
                      <TableCell
                        padding='none'
                        className={classes.head}
                        align='center'
                      >
                        {context.t('sample_distance')}
                      </TableCell>
                      {showOnlyRelatedProbability ? (
                        <TableCell
                          padding='none'
                          className={classes.head}
                          align='center'
                        >
                          {context.t('probability')}
                        </TableCell>
                      ) : (
                        [
                          <TableCell
                            key='rpw-header-prob'
                            padding='none'
                            className={classes.head}
                            align='center'
                          >
                            {context.t('prob')}
                          </TableCell>,
                          <TableCell
                            key='rpw-header-pi'
                            padding='none'
                            className={classes.head}
                            align='center'
                          >
                            {JSON.parse(process.env.REACT_APP_SHOW_COSTISTENCY)
                              ? context.t('consistency')
                              : context.t('pi')}
                          </TableCell>,
                        ]
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.multi &&
                      row.multi.map((moreRow) => {
                        const moreIsLeakData = getAlgoStatusClassName(
                          moreRow,
                          context
                        );
                        return (
                          <TableRow
                            key={`${moreRow.SampleID}-${moreRow.CoupleLeakNdx}`}
                            className={classes.root}
                            hover
                          >
                            <TableCell
                              padding='none'
                              style={{ width: '32px' }}
                            />
                            <TableCell padding='none' component='td'>
                              <div
                                className={moreIsLeakData.class}
                                title={moreIsLeakData.title}
                              />
                            </TableCell>

                            <TableCell padding='none' align='center'>
                              {UIDateFormater(
                                moreRow.SampleDateAndTime,
                                DateType.DATE_AND_TIME,
                                timeZone
                              )}
                            </TableCell>

                            <TableCell padding='none' align='center'>
                              <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  const selectedAlert = {
                                    type: 'alert',
                                    id: moreRow.LeakID,
                                  };
                                  props.navigateToFeature(
                                    moreRow.LeakID,
                                    selectedAlert
                                  );
                                }}
                              >
                                {moreRow.LeakID}
                              </a>
                            </TableCell>

                            <TableCell
                              padding='none'
                              align='center'
                              // style={{ width: '12%' }}
                            >
                              {moreRow.IntensityA}
                            </TableCell>
                            <TableCell
                              padding='none'
                              align='center'
                              // style={{ width: '10%' }}
                            >
                              {moreRow.IntensityI}
                            </TableCell>
                            <TableCell
                              padding='none'
                              align='center'
                              // style={{ width: '10%' }}
                            >
                              {/* {moreRow.haveDistanceMatch ? <strong>{getDistance(moreRow.DistanceAM, distanceFactor)}</strong> : getDistance(moreRow.DistanceAM, distanceFactor)} */}
                              {getDistance(moreRow.DistanceAM, distanceFactor)}
                            </TableCell>
                            {/* <TableCell padding='none' align='center' style={{ display: "flex", justifyContent: "space-between" }}> */}
                            <TableCell padding='none' align='center'>
                              {getDistance(moreRow.DistanceIM, distanceFactor)}
                              {/* {moreRow.distancesHTML.map((val, index, allValues) => (
                                <span key={index} style={{ flex: 1, textAlign: "center", borderRight: index < allValues.length - 1 ? "1px solid #ccc" : "none" }}>
                                  {val.bold ? <strong>{val.value}</strong> : val.value}
                                </span>
                              ))} */}
                            </TableCell>
                            {showOnlyRelatedProbability ? (
                              <TableCell padding='none' align='center'>
                                {moreRow.AlertType === 2 &&
                                moreRow.probability_irrigation
                                  ? moreRow.probability_irrigation
                                  : moreRow.Probability}
                              </TableCell>
                            ) : (
                              [
                                <TableCell
                                  key={'prob-' + row.ID}
                                  padding='none'
                                  align='center'
                                >
                                  {moreRow.Probability}
                                </TableCell>,
                                <TableCell
                                  key={'prob-irrg-' + row.ID}
                                  padding='none'
                                  align='center'
                                >
                                  {moreRow.probability_irrigation}
                                </TableCell>,
                              ]
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
Row.contextTypes = {
  t: PropTypes.func.isRequired,
};
Row.propTypes = {
  distanceFactor: PropTypes.number,
  expandedRow: PropTypes.number,
  handleSelection: PropTypes.func,
  isExtended: PropTypes.bool,
  navigateToFeature: PropTypes.func,
  rmThreshold: PropTypes.number,
  row: PropTypes.object,
  selectedRow: PropTypes.object,
  setExpandedRow: PropTypes.func,
  showOnlyRelatedProbability: PropTypes.bool,
  timeZone: PropTypes.string,
};

export default function CoupleSamplesTable(props, context) {
  const {
    timeZone,
    coupleSamples,
    selectCoupleSample,
    selectedSample,
    navigateToFeature,
    distanceFactor,
    selectedFeature,
    showOnlyRelatedProbability,
  } = props;

  const data = coupleSamples.groupData || [];
  const totalDataCount = coupleSamples.data && coupleSamples.data.totalRows; // coupleSamples.data ? coupleSamples.data.totalRows / rowsCountFactor : 0; // now we get 3 rows per coupleOutput
  const rowsPerPage = COUPLE_SAMPLES_COUNT_FOR_PAGE;

  const [expandedRow, setExpandedRow] = useState(null);
  // const [rowsPerPage, setRowsPerPage] = useState(COUPLE_SAMPLES_COUNT_FOR_PAGE / 3);
  const [paginationPage, setPaginationPage] = useState(0);

  useEffect(() => {
    setPaginationPage(0);

    return () => {
      setPaginationPage(0);
    };
  }, [selectedFeature]);
  const classes = useRowStyles();

  const handleChangePage = (_event, offset) => {
    const pageValue = offset / rowsPerPage;
    setPaginationPage(pageValue);
    props.onPageIndexChange(pageValue);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, rowsCount));
  //   // setPage(0);
  //   handleChangePage(event, 0);
  // };
  const rmThreshold =
    props.deviceSync === 1
      ? props.algParams.RadioQualityThreshold
      : props.algParams.GpsQualityThreshold;

  return (
    <TableContainer component={Paper} classes={{ root: classes.container }}>
      <TableContainer
        component={Paper}
        classes={{ root: classes.innerContainer }}
      >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              {coupleSamples.isExtendedInfo && <TableCell padding='none' />}
              <TableCell padding='none'>
                <img
                  src={require('../../../../images/drop.png')}
                  height={22}
                  width={22}
                />
              </TableCell>
              <TableCell padding='none' className={classes.head} />
              <TableCell
                padding='none'
                className={classes.head}
                align='center'
                style={{ width: '20%' }}
              >
                {context.t('sample_time')}
              </TableCell>
              <TableCell
                padding='none'
                className={classes.head}
                align='center'
                // style={{ width: '70px' }}
              >
                {context.t('leak_id')}
              </TableCell>
              <TableCell
                padding='none'
                className={classes.head}
                align='center'
                // width={'70px'}
              >
                {context.t('weighted_intensity')}
              </TableCell>
              <TableCell
                padding='none'
                className={classes.head}
                align='center'
                // width={'70px'}
              >
                {context.t('sample_intensity')}
              </TableCell>
              <TableCell
                padding='none'
                className={classes.head}
                align='center'
                // width={'70px'}
              >
                {context.t('weighted_distance')}
              </TableCell>

              <TableCell padding='none' className={classes.head} align='center'>
                {context.t('sample_distance')}
              </TableCell>
              {showOnlyRelatedProbability ? (
                <TableCell
                  padding='none'
                  className={classes.head}
                  align='center'
                  // width={'70px'}
                >
                  {context.t('probability')}
                </TableCell>
              ) : (
                [
                  <TableCell
                    key='header-prop'
                    padding='none'
                    className={classes.head}
                    align='center'
                    // width={'70px'}
                  >
                    {context.t('prob')}
                  </TableCell>,
                  <TableCell
                    key='header-prop-irrg'
                    padding='none'
                    className={classes.head}
                    align='center'
                    // width={'70px'}
                  >
                    {JSON.parse(process.env.REACT_APP_SHOW_COSTISTENCY)
                      ? context.t('consistency')
                      : context.t('pi')}
                  </TableCell>,
                ]
              )}
              {/* <TableCell padding='none' className={classes.head} align="center">{context.t('P Irrigation')}</TableCell> */}
              {/* <TableCell padding='none' className={classes.head} align="center">{context.t('RM')}</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((row) => (
                <Row
                  key={row.ID}
                  row={row}
                  isExtended={coupleSamples.isExtendedInfo}
                  selectedRow={selectedSample}
                  handleSelection={selectCoupleSample}
                  timeZone={timeZone}
                  expandedRow={expandedRow}
                  setExpandedRow={setExpandedRow}
                  navigateToFeature={navigateToFeature}
                  distanceFactor={distanceFactor}
                  rmThreshold={rmThreshold}
                  showOnlyRelatedProbability={showOnlyRelatedProbability}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <React.Fragment>
        <Pagination
          size='large'
          otherPageColor='default'
          currentPageColor='primary'
          limit={rowsPerPage}
          offset={paginationPage * rowsPerPage}
          total={totalDataCount}
          onClick={handleChangePage}
        />
        {/* <TablePagination
          // classes={{
          //   root: classes.pagination,
          //   'MuiTypography-body2': classes.pagination
          // }}
          rowsPerPageOptions={[rowsCount]}
          component="div"
          colSpan={3}
          count={totalDataCount}
          rowsPerPage={rowsPerPage}
          page={paginationPage}
          // SelectProps={{
          //   inputProps: { 'aria-label': 'rows per page' },
          //   native: true,
          // }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        /> */}
      </React.Fragment>
    </TableContainer>
  );
}

CoupleSamplesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

CoupleSamplesTable.propTypes = {
  algParams: PropTypes.object,
  coupleSamples: PropTypes.array,
  distanceFactor: PropTypes.number,
  navigateToFeature: PropTypes.func,
  onPageIndexChange: PropTypes.func,
  selectCoupleSample: PropTypes.func,
  selectedFeature: PropTypes.object,
  selectedSample: PropTypes.object,
  showOnlyRelatedProbability: PropTypes.bool,
  timeZone: PropTypes.string,
};

CoupleSamplesTable.defaultProps = {
  showOnlyRelatedProbability: false,
};
