import PropTypes from 'prop-types';
import React from 'react';

import { Column, Table } from 'fixed-data-table-2';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import CAddAlert from '../../containers/CAlertsSettings/CAddAlert';
import CAlertsSettings from '../../containers/CAlertsSettings/CAlertsSettings';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

require('./AlertsTable.scss');

const AlertTable = (props, context) => {
  const {
    items,
    indexMap,
    selectedFeature,
    isDisplayCityColumn,
    addingManualAlertMode,
    pHeight,
    pWidth,
    sortProp,
    timeZone,
    rowClickEvent,
    goToRow,
    rowClassNameGetter,
    t,
  } = props;


  //document.title = 'AQS • Alerts';

  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };

  return (
    <div>
      <CAddAlert
        isOpen={addingManualAlertMode}
        showAddAlertForm={props.addNewManualAlert}
      />
      <Table
        maxHeight={pHeight - 109}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
        scrollToRow={goToRow}
        {...props}
      >
        <Column
          columnKey='Burst'
          width={30}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field='Burst' />}
        />
        <Column
          columnKey='Priority'
          width={30}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field='Priority' />}
        />
        <Column
          columnKey='Probability'
          width={70}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field='Probability' />}
        />
        <Column
          columnKey='id'
          width={70}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field='id' />}
        />
        <Column
          columnKey='DetectedAt'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={
            <TextCell
              data={items}
              field='DetectedAt'
              dateType={DateType.DATE}
              additional={timeZone}
            />
          }
        />
        <Column
          columnKey='AlertType'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={
            <CSelectCell
              data={items}
              options={props.optionList.options.AlertType}
              field='AlertType'
            />
          }
        />
        <Column
          columnKey='AlertState'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={
            <CSelectCell
              data={items}
              options={props.optionList.options.AlertState}
              field='AlertState'
            />
          }
        />
        <Column
          columnKey='StreetAddress'
          headerClassName='comment'
          cellClassName='comment'
          width={120}
          flexGrow={3}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field='StreetAddress' />}
        />
        {isDisplayCityColumn && (
          <Column
            columnKey='City'
            headerClassName='comment'
            cellClassName='comment'
            width={80}
            flexGrow={2}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field='City' />}
          />
        )}
        <Column
          columnKey='Comment'
          headerClassName='comment'
          cellClassName='comment'
          width={120}
          flexGrow={3}
          header={t('comment')}
          cell={<TextCell data={items} field='Comment' />}
        />
      </Table>

      {items && items.length > 0 && (
        <CTableActions type='alerts' notify={props.notify} />
      )}
      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t('selected_selectedindex_total_total', {
            selectedIndex: indexMap[selectedFeature] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}

      <button
        className='btn-success create-item'
        onClick={() => {
          props.addNewManualAlert(true);
        }}
        type='button'
        disabled={!props.user.canAddAlert}
      >
        <i title={context.t('add_alert')} />
      </button>
    </div>
  );
};

AlertTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

const alertTable = TableMixin(AlertTable, CAlertsSettings);
export default alertTable;
