import { selectFeature } from "./selectors";
import { setMapDrawMode } from "./setters";
import * as actionsPressureAlerts from "./PrsTransientAlertsActions";
import * as actionsSensors from "./SensorsActions";
import * as types from "../constants/ActionTypes";
import * as endPoints from "../constants/EndPoints";
import { fetchMiddleware, setIndexes } from "./MiddlewareActions";
import { decToHex, formatMobileDeviceID } from "../containers/UIhelper/UIhelper";
import { isEqual } from "lodash";
const ACTIONS = {
  SET_MOBILE_SAMPLES_FILTERS: 'SET_MOBILE_SAMPLES_FILTERS',
  REQUEST_MOBILE_SAMPLES: 'REQUEST_MOBILE_SAMPLES',
  RECEIVE_MOBILE_SAMPLES: 'RECEIVE_MOBILE_SAMPLES'
};
/**
 * Globals actions here:
 */
export const handlePathChangeEvent = (selectedProject) => (dispatch) => {
  dispatch(selectFeature(selectedProject, '', ''));
  dispatch(setMapDrawMode(false));
  dispatch(actionsPressureAlerts.selectTransientAlertSample({}));
  dispatch(actionsSensors.selectSensorNoiseMsg(selectedProject, {}));
};
export const getPcaForAlert = (alertId) => {
  return (dispatch, getState) => {
    const path = endPoints.ALERTS_ENDPOINT + "/pca/" + alertId;
    dispatch(receivePcaDataForAlert(alertId, null));
    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        if (json.data.length > 0) {
          const dataObject = json.data[0];
          let diameterInch = (dataObject.PipeDiameterInch <= 0) ? 1 : dataObject.PipeDiameterInch;
          diameterInch = (Math.round(diameterInch * 4) / 4); // round in steps of 0.25

          dataObject.PipeDiameterInch = diameterInch;

          dispatch(receivePcaDataForAlert(alertId, dataObject));
        }
      }
    });
  };
};
const receivePcaDataForAlert = (alertId, data) => {
  return {
    type: types.RECEIVE_PCA_DATA_FOR_ALERT,
    alertId,
    data,
  };
};

// getTasksSamples
export const getSamples = (tasksIds, page = 0, count = 30) => (dispatch, getState) => {
  dispatch(getSamplesAction());
  // fetch task samples:
  if (tasksIds && tasksIds.length > 0) {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const filters = state.mobile.samples.filters;
    const qStr = [
      `tasks=${tasksIds.join(',')}`,
      // `page=${page}`,
      // `rows=${count}`
    ];
    if (filters && filters.length > 0) {
      qStr.push(`filters=${JSON.stringify(filters)}`)
    }

    const url = `${endPoints.END_POINT}/mobilesamples/samples/${projectId}?${qStr.join('&')}`;
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        const samples = json.data.map((sample) => {
          console.log('samples in fetchMiddleware getSamples');

          const hex = isNaN(sample.device_id) ? '--' : decToHex(Number(sample.device_id));
          const hexFormated = formatMobileDeviceID(hex);
          return { ...sample, device_id_hex: hexFormated };
        });
        const indexMap = setIndexes(samples, 'sample_uid');
        dispatch(getSamplesAction(true, samples, indexMap));
        dispatch(getImagesForSamples(tasksIds, page, count));
      }
    });
  }
};
export const getImagesForSamples = (tasksIds, page = 0, count = 30) => (dispatch, getState) => {

  if (tasksIds && tasksIds.length > 0) {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const filters = state.mobile.samples.filters;

    const qStr = [
      `tasks=${tasksIds.join(',')}`,
      // `page=${page}`,
      // `rows=${count}`
    ];
    if (filters && filters.length > 0) {
      qStr.push(`filters=${JSON.stringify(filters)}`)
    }

    const url = `${endPoints.END_POINT}/mobilesamples/samples/${projectId}/images?${qStr.join('&')}`;
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        const state = getState();
        const samples = state.mobile.samples;
        const items = [...samples.items].map((item) => ({ ...item, sample_images: '' }));
        json.data.forEach(({ id, images }) => {
          if (samples.indexMap[id] !== undefined) {
            items[samples.indexMap[id]].sample_images = images;
          }
        });

        dispatch(getSamplesAction(true, items, samples.indexMap));
      }
    });
  }
}
export const getSamplesAction = (
  isReceiveData = false,
  samples = [],
  indexMap = {}
) => {
  return {
    type: isReceiveData
      ? ACTIONS.RECEIVE_MOBILE_SAMPLES
      : ACTIONS.REQUEST_MOBILE_SAMPLES,
    payload: {
      samples,
      indexMap,
    },
  };
};
export const setSamplesFilters = (filters) => (dispatch, getState) => {
  const state = getState();
  const currentFilters = state.mobile.samples.filters;
  dispatch(setFiltersAction(filters));

  if (!isEqual(filters, currentFilters)) {
    const selectedTasks = state.mobile.tasks.selectedTask;
    dispatch(getSamples(selectedTasks.join(',')));
  }
}
const setFiltersAction = (filters) => ({
  type: ACTIONS.SET_MOBILE_SAMPLES_FILTERS,
  payload: filters,
});
