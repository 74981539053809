import { Cell } from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import React from 'react';

const classNames = require('classnames');

export default class SortableHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortDir: this.props.sortTable?.sortDir || 'asc',
    };
    this.onSortChange = this.onSortChange.bind(this);
  }
  onSortChange(e) {
    const sortDir = this.state.sortDir === 'desc' ? 'asc' : 'desc';
    this.setState({ sortDir });
    this.props.sortTable.sort(this.props.columnKey, sortDir);
  }
  render() {
    const { columnKey, sortTable, title } = this.props;
    const { sortDir } = this.state;
    const activeSortDir = sortTable.sortDir || sortDir;
    const titles = {
      Priority: this.context.t('blank_space'),
      ID: this.context.t('id'),
      id: this.context.t('id'),
      DetectedAt: this.context.t('detected'),
      AlertType: this.context.t('type'),
      AlertTypeMeaning: this.context.t('type'),
      AlertState: this.context.t('status'),
      alert_state: this.context.t('status'),
      AlertStateMeaning: this.context.t('status'),
      SensorStatus: this.context.t('status'),
      comment: this.context.t('comment'),
      DaysInactive: this.context.t('last_run_days'),
      DeviceID: this.context.t('device_id'),
      SensorID: this.context.t('sensor_id'),
      Address: this.context.t('address'),
      StreetAddress: this.context.t('address'),
      City: this.context.t('city'),

      Intensity: this.context.t('intensity'),
      Probability: this.context.t('prob'),
      probability_irrigation: this.context.t('consistency'),
      Distance: this.context.t('distance'),
      // Sensors
      warning: this.context.t('w'),
      DeviceInstallationDate: this.context.t('install_date'),
      Nc2: this.context.t('noise'),
      Nc1: this.context.t('min'),
      // 'Nc2': this.context.t('2'),
      Nc3: this.context.t('nc_3'),
      Nc3f1: this.context.t('low'),
      Nc3f2: this.context.t('med'),
      Nc3f3: this.context.t('hi'),

      // Samples
      sampleTime: this.context.t('sample_time'),
      weightedDistance: this.context.t('weighted_distance'),
      instanceIntensity: this.context.t('intensity'),
      weightedIntensity: this.context.t('weighted_intensity'),
      instanceDistance: this.context.t('distance'),
      LeakID: this.context.t('leak_id'),
      // Couples
      warningEnum: this.context.t('blank_space'),
      PathLengthM: this.context.t('pipe_length'),
      Material: this.context.t('material'),
      Sensor1: this.context.t('sensor_1'),
      Sensor2: this.context.t('sensor_2'),
      LastDaemonActivity: this.context.t('updated'),

      // Couples Samples
      SampleDateAndTime: this.context.t('sample_time'),
      IntensityA: this.context.t('weighted_intensity'),
      IntensityI: this.context.t('sample_intensity'),
      DistanceAM: this.context.t('weighted_distance'),
      DistanceIM: this.context.t('sample_distance'),

      // Users
      UserName: this.context.t('user_name'),
      Email: this.context.t('email'),
      FirstName: this.context.t('first_name'),
      LastName: this.context.t('last_name'),
      CustomerName: this.context.t('customer_name'),

      // SOPs
      Type: this.context.t('type'),
      State: this.context.t('state'),
      AssetID: this.context.t('asset_id'),
      Model: this.context.t('model'),

      // Projects
      SensorActivityRatioDaysBack: this.context.t('threshold'),
      PilotModeLabel: this.context.t('mode'),
      ShortName: this.context.t('project_name'),

      // Customers
      Name: this.context.t('name'),
      CustomerName: this.context.t('customer_name'),
      ContactPerson: this.context.t('contact_name'),

      // iQuarius
      TaskName: this.context.t('task_name'),
      CreationTime: this.context.t('creation_time'),
      CreationDate: this.context.t('creation_date'),
      SamplesCount: this.context.t('samples_count'),
      TaskID: this.context.t('task_id'),
      SampleTime: this.context.t('sample_time'),
      Status: this.context.t('status'),
      Quality: this.context.t('quality'),
      Comments: this.context.t('comments'),
      DistanceOfLeakFromDevice1: this.context.t('dist_m'),

      // Mobile Alerts
      alert_id: this.context.t('alert_id'),
      CreationDate: this.context.t('creation_date'),
      Intensity: this.context.t('intensity'),
      Type: this.context.t('type'),
      TaskName: this.context.t('task_name'),
      Address: this.context.t('address'),
      Status: this.context.t('status'),
      Comment: this.context.t('comment'),

      // Interferences
      StartTime: this.context.t('start_time'),
      EndTime: this.context.t('end_time'),
      StartFrequency: this.context.t('start_frequency'),
      EndFrequency: this.context.t('end_frequncy'),

      // PCA
      DetectionDateTime: this.context.t('detected'),
      AlertTypeName: this.context.t('type'),
      AlertStateName: this.context.t('status'),

      // PrsTransientAlerts
      SessionNumEvents: this.context.t('n'),
      CreateDate: this.context.t('create_date'),
      EndDate: this.context.t('end_date'),
      LastEventTime: this.context.t('last_event_date'),
      LastEventMinValue: this.context.t('min_value'),
      LastEventMaxValue: this.context.t('max_value'),

      // NoiseAlerts
      InsertDate: this.context.t('alert_date'),
      EventDate: this.context.t('event_date'),
      AlertDate: this.context.t('alert_date'),
      Status: this.context.t('status'),
      SensorId: this.context.t('sensor_id'),
      Comment: this.context.t('comment'),

      // MobileSamples
      sample_time: this.context.t('sample_time'),
      device_id: this.context.t('device_id'),
      sample_state: this.context.t('status'),
      engine_intensity: this.context.t('intensity_db'),
      mobile_intensity: this.context.t('intensity_db'),
      mobile_quality: this.context.t('quality'),
      engine_quality: this.context.t('clarity'),
      user_comment: this.context.t('comment'),
      user_sop_classify: this.context.t('access_point'),
      user_sample_classify: this.context.t('classify'),
      Probability: this.context.t('probability'),
      DmaComment: this.context.t('boundaries'),
      sample_images: this.context.t('sample_images'),

      timestamp: this.context.t('time'),
      user_name: this.context.t('user'),
      is_assambly: this.context.t('assambly'),

      // G5 tables
      LastStatusReportTime: this.context.t('last_updated'),
      BatteryLevel: this.context.t('battery_level'),
      BatteryLevelPost: this.context.t('battery_post'),
      AccessTechnologyName: this.context.t('tech'),
      IEMI: this.context.t('iemi'),
      ICCID: this.context.t('iccid'),
      StatusTimeZone: this.context.t('time_zone'),
      HardwareRevision: this.context.t('hw_v'),
      FirmwareRevision: this.context.t('fw_v'),
      RSRQ_db: this.context.t('rsrq'),
      RSRP_dbm: this.context.t('rsrp'),
      StatusFmReceiverChannel_10k: this.context.t('fm1'),
      StatusFmReceiverChannel2_10k: this.context.t('fm2'),
      fm_channel_snr: this.context.t('snr1'),
      fm_channel2_snr: this.context.t('snr2'),
      time_source: this.context.t('time_source'),
      is_valve_monitor: this.context.t('valve_leak'),
      fmReceiverChannel2_Config: this.context.t('fm2_conf'),
      APN: this.context.t('apn'),
      Server: this.context.t('server'),
      FallbackServer: this.context.t('secondary'),
      modem_fw_rev: this.context.t('modem_fw'),
      dfu_fw_rev: this.context.t('dfu_fw'),
      dfu_type: this.context.t('dfu_type'),
      dfu_block_num: this.context.t('dfu_block'),
      random_backoff_connect: this.context.t('random_backoff_connect'),


      SchedulerRefTime: this.context.t('sample_time'),
      ConfigTimeZone: this.context.t('time_zone'),
      SchedulerCorrInterval: this.context.t('corr_interval'),
      SchedulerCorrNumSamples: this.context.t('corr_num_samples'),
      SchedulerNoiseInterval: this.context.t('noise_interval'),
      SchedulerNoiseNumSamples: this.context.t('noise_num_samples'),
      SchedulerNoiseTxTime: this.context.t('noise_tx_time'),
      SchedulerWakeupBefor: this.context.t('wakeup_befor'),
      SchedulerRandomDelay: this.context.t('random_delay'),
      AudioRecordingDurationSec: this.context.t('noise_audio_duration'),
      CorrelationAudioRecordingDurationSec: this.context.t('corr_audio_duration'),
      CorrelationRadioRecordingDurationSec: this.context.t('corr_radio_duration'),
      AudioReportEnabled: this.context.t('audio_report_enabled'),
      CorrelationAudioSamplingFrequency: this.context.t('corr_sample_freq'),
      FmReceiverChannel_10k: this.context.t('fm1'),
      FmReceiverChannel2_10k: this.context.t('fm2'),
      fmReceiverChannel2_Configuration_abbrviation: this.context.t('fm2_conf'),
      LTEMode: this.context.t('lte_mode'),
      APN: this.context.t('apn'),
      Server: this.context.t('server'),
      FallbackServer: this.context.t('secondary'),
      catm_packet_size: this.context.t('catm_packet'),
      catm_rx_timeout: this.context.t('catm_rx_tout'),
      catm_tx_timeout: this.context.t('catm_tx_tout'),
      catm_flags: this.context.t('catm_flags'),
      nbiot_packet_size: this.context.t('nbiot_packet'),
      nbiot_rx_timeout: this.context.t('nbiot_rx_tout'),
      nbiot_tx_timeout: this.context.t('nbiot_tx_tout'),
      nbiot_flags: this.context.t('nbiot_flags'),

      // valve alerts
      create_date: this.context.t('create_date'),
      sensor_id: this.context.t('sensor_id'),

    };

    if (this.props.tableName && this.props.tableName === 'AlertsTableNoise') {
      switch (this.props.tableName) {
        case 'AlertsTableNoise': {
          titles.CreateDate = this.context.t('event_date');
        }
      }
    }
    const active = sortTable.sortBy === columnKey;
    let columnKeyClass;
    switch (columnKey) {
      case 'Priority':
        columnKeyClass = 'priority';
        break;
      case 'Warning':
        columnKeyClass = 'warning';
        break;
      case 'IsLeak':
        columnKeyClass = 'drop';
        break;
      default:
        columnKeyClass = '';
        break;
    }
    const cellClasses = classNames(
      columnKey === 'Priority' ? 'priority' : columnKey,
      'sortable',
      'sort-' + activeSortDir,
      { 'sort-active': active },
      columnKeyClass
    );
    if (columnKey === 'SessionNumEvents') {
      return (
        <Cell
          onClick={this.onSortChange}
          className={cellClasses}
          title={this.context.t('number_of_transients')}
        >
          {titles[columnKey]}
        </Cell>
      );
    } else {
      return (
        <Cell
          onClick={this.onSortChange}
          className={cellClasses}
          title={title || titles[columnKey]}
        >
          {titles[columnKey]}
        </Cell>
      );
    }
  }
}

SortableHeaderCell.contextTypes = {
  t: PropTypes.func.isRequired,
};
